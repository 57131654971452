<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['takingHormoneSupplements'])">
      <single-answer-question
        title="label.takingHormoneSupplements"
        :value="takingHormoneSupplements"
        :options="$options.responseOptions"
        yes-no
        @input="onFieldChange('takingHormoneSupplements', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'HormonalHistoryTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  responseOptions: YES_NO_RESPONSE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    takingHormoneSupplements: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped></style>
